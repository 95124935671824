/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { MONTHLY_VOLUME_OPTIONS_ARRAY } from "_constants/variables";
import { handleError } from "_helpers";
import { OrdersService } from "_services";
import { selectActiveOrderId } from "_store/orders/selector";
import { getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import CheckboxField from "components/atomic/molecules/fields/CheckboxField";
import FieldWrapper from "components/atomic/molecules/fields/FieldWrapper";
import SelectField from "components/atomic/molecules/fields/SelectField";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const ErrorText = styled.div`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;

const ErrorMessage = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 4px gray inset;
  font-family: monospace;
  word-wrap: break-word;
`;

const StyledButton = styled(Button)`
  min-width: 80px;

  &:last-child {
    margin-left: auto;
  }
`;

const STEPS = {
  init: "step-init",
  start: "step-start",
  loading: "step-loading",
  success: "step-success",
  error: "step-error",
};

const initValues = { online: false, inPerson: false, monthlyVolume: MONTHLY_VOLUME_OPTIONS_ARRAY[0]?.value || "" };

const rules = {
  monthlyVolume: () => ({
    required: "Please select Monthly Volume",
  }),
};

const CreditCardProcessingUpsell = ({ upsellCode, onCancel }) => {
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
  } = useForm({ defaultValues: initValues, reValidateMode: "onChange" });

  const values = useWatch({ control });
  const isSelected = Object.values(values).some((value) => value === true);

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);

  const [step, setStep] = useState(STEPS.start);
  const [error, setError] = useState(null);

  function handleCloseModal({ update = false }) {
    setError(null);
    setStep(STEPS.init);
    onCancel();
    // update && dispatch(requestUpdate("REFRESH"));
    update && dispatch(getOrder({ orderId, customerId, refreshOrders: true }));
  }

  async function createUpsell() {
    try {
      if (isSelected) {
        setStep(STEPS.loading);

        const body = {
          order: { processingOption: "UPSELL", campaignId: 29 },
          products: [
            {
              code: { code: upsellCode },
              online: values.online || false,
              inPerson: values.inPerson || false,
              monthlyVolume: values.monthlyVolume || null,
            },
          ],
        };

        const request = await OrdersService.createDelayedUpsell({ orderId, body });

        if (request?.status === 200) {
          if (request?.data?.products[0]?.paymentStatus === "Declined") {
            setError({
              message: "Uh oh - your card on file has been declined. Please update your payment method to proceed",
            });
            setStep(STEPS.error);
          } else {
            setStep(STEPS.success);
          }
        } else {
          setStep(STEPS.error);
          setError({ message: "Unknown Error" });
        }
      }
    } catch (response) {
      setStep(STEPS.error);
      setError(handleError(response));
    }
  }

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: false })}>
          <Modal.Header closeButton />
          <Modal.Body>
            <Row className="py-4">Would you like to setup accepting Credit/Debit Card payments for your business?</Row>
            <Row>Select how you will process credit card payments below:</Row>
            <Row className="py-2">
              <form>
                <FieldWrapper
                  column
                  name="cardProcessing"
                  errors={
                    !isSelected && isSubmitted
                      ? { cardProcessing: { message: "Please select at least one option" } }
                      : {}
                  }
                >
                  <CheckboxField name="online" label="Online" control={control} errors={errors} />
                  <CheckboxField name="inPerson" label="In-Person" control={control} errors={errors} />
                </FieldWrapper>
                <Row className="py-2 mt-2">Enter your currently monthly sales volume:</Row>
                <SelectField
                  column
                  isSearchable={false}
                  name="monthlyVolume"
                  placeholder="Monthly Sales Volume..."
                  options={MONTHLY_VOLUME_OPTIONS_ARRAY}
                  control={control}
                  errors={errors}
                  rules={rules.monthlyVolume()}
                ></SelectField>
              </form>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-4">
              <StyledButton variant="secondary" onClick={() => handleCloseModal({ update: false })}>
                Close
              </StyledButton>
              <StyledButton variant="primary" disabled={!isSelected} onClick={handleSubmit(createUpsell)}>
                Submit
              </StyledButton>
            </div>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => {}}>
          <StyledBody>
            <SpinnerBlock message="Sending Request..." />
          </StyledBody>
        </Modal>
      );

    case STEPS.success:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader closeButton></StyledHeader>
          <StyledBody>
            <Row className="py-4">
              <Icon inline icon="misc:success-check" size="32px" color="#32D583" />
              &nbsp;Thank you! Your Credit Card Processing request was sended successfully!
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader closeButton></StyledHeader>
          <StyledBody>
            <Row className="py-4">
              <ErrorText>
                <Icon inline icon="error:alert" size="24px" />
                &nbsp;Error:
              </ErrorText>
              <ErrorMessage>{error?.message || "Something went wrong..."}</ErrorMessage>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

CreditCardProcessingUpsell.propTypes = {
  upsellCode: PropTypes.string,
  onCancel: PropTypes.func,
};

export default CreditCardProcessingUpsell;
